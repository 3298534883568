import Login from './Login';
import OutletStatus from './OutletStatus';
import SalesmanDeviceId from './SalesmanDeviceId';

const COMMON ={
    ...Login,
    ...OutletStatus,
    ...SalesmanDeviceId
}

export default COMMON;