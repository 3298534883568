export default {
    OutletStatusHeader: "Outlet Status - Online / Offline",
    ActivityLog: "Activity Log",
    ExportOutlets: "Export Outlets",
    Save: "Save",
    SearchMesagePart1: "“A maximum of",
    SearchMessageBold: " 10 outlet codes ",
    SearchMesagePart2: "can be searched by comma separated values”",
    Filter: "Filter:",
    DTCode: "DT Code",
    DTSiteCode: "DT Site Code",
    AllSelected: "ALL Selected",
    SelectAllOption: "Select All",
    BulkUpload: "Online / Offline Status",
    SaveHeader: "Online/Offline Outlet Update",
    SaveMessage: "You are changing the Outlet status, this will affect functionality associated with placing orders for the outlet. Are you sure you want to continue?",
    Cancel: "Cancel",
    UpdateConfirm: "Yes, Save and update status",
    AlertMessage: "All your unsaved changes will be lost. Kindly save the changes before refreshing the page",
    SuccessToastMsg1: "Online / Offline status changes have been saved. You have made changes to ",
    SuccessToastMsg2: " number of rows.",
    ErrorToastMessage: "Status cannot be updated due to error. Kindly update again.",
    Uploading: "Uploading",
    statusSuccess: "Online/Offline Status updates successful",
    fileError: "The data cannot be saved due to error. Kindly recheck the file to upload and update the data again.",
    spaceErrorHeader: "File name",
    spaceError: "File name should not contain spaces. Please rename the file and upload again.",
    extensionErrorHeader: "File Format",
    extensionError: "File is not in .csv format. Please upload with the given template in .csv",
    uploadFailed: "Upload Failed. Please check file data and upload again.",
    selectFile: "Please select a file before uploading.",
    BulkUploadError: "Upload Failed. Please check file data and upload again.",
    sizeErrorHeader: "File size exceeded",
    sizeError: "The maximum file size that can be updated is* 10 MB. Please reduce the file size and upload",
    outletStatusErrorHeader: "Outlet Status Error",
    outletStatusError: "Outlet status can be only be 0 or 1. You have a wrong outlet status mentioned in row ",
    outletStatusErrorSuffix: ". Please update the correct status and re-upload",
    blankValueErrorHeader: "File error",
    blankValueError: "There are null values present in the row number ",
    blankValueErrorSuffix: ". We cannot have null values present for an active row. Kindly correct the data and upload again.",
    duplicateDataErrorHeader: "Errors in data",
    duplicateDataError: "There are duplicate records present in the file with respect to DT Code, DT Site Code ,Outlet Code in row number ",
    duplicateDataErrorSuffix: ". Kindly check the file to remove duplicate records and upload again.",
    nonNumericErrorHeader: "Errors in data",
    nonNumericError: "There are  blank space or special characters in prefix or suffix of values in row number ",
    nonNumericErrorSuffix: ". Please correct the data and reupload the file",
    technicalErrorHeader: "Technical Error",
    technicalError: "File can not be uploaded due to some technical error, Please try to upload again.",
    connectivityErrorHeader: "Connectivity Error",
    connectivityError: "File cannot be uploaded due to connectivity issue. Kindly check your internet connection and upload again.",
    DataMissing: "Data missing",
    downloadTemplate: "Download Template",
    uploadCSV: "Upload CSV with updated online/offline status",
    Uploaded: "Uploaded",
    ErrorInData: "Errors in data",
    AcceptedFormat: "Accepted formats:",
    MaxFileSize: "Maximum file size:",
    Browse: "Browse",
    UploadedFile: "Uploaded File",
    Update: "Update",
    ExportHeader: "Export Outlet Status Data",
    ExportSubHeader: "Enter/Search DT Code to export",
    Note: "Note: ",
    ExportFileTypeText: "Exported and downloaded as a CSV file.",
    Export: "Export",
    Updating: "Updating"
}