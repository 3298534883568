const SalesmanDeviceID = {
    SalesmanDeviceId_Header: "Salesman Device ID Update",
    SalesmanDeviceId_ActivityLog: "Activity Log",
    SalesmanDeviceId_ExportDeviceId: "Export Device ID",
    SalesmanDeviceId_BulkUpdate: "Bulk Update Device ID",
    Activity_Log_Subheading:'Log of all files uploaded with Sales-person Device ID Update',
    File_Name: 'File name',
    Uploaded_By: 'Uploaded by',
    Download: 'Download',
    VerticalLine: '|',
    Enable: "Enable",
    Disable: "Disable",
    Enable_Modal_Title: "Enable Device ID",
    Disable_Modal_Title: "Disable SM Device ID validation check",
    Enable_Modal_SubTitle: "You are enabling the SM Device ID validation check for  all DTs",
    Disable_Modal_SubTitle: "You are disabling the SM Device ID validation check for all DTs",
    Enable_SM_Device_ID:"Enable SM Device ID Validation",
    Enable_SM_Device_ID_Desc:"This configuration is at the market level. When switched on device ID validations will be done for the salesman during login",
    SMCode_Placeholder: 'A maximum of 10 SM Code can be searched by comma separated values',
    Phone_Number_Placeholder: 'A maximum of 10 phone numbers can be searched by comma separated values',
    Update_Modal_Header: 'Please enter the device ID you want to map against the Salesman.',
    Update_Modal_SubHeader: 'Note that after this update , respective salesman will not be able to login with old device',
    Update_Modal_Input_Label: 'New Device ID',
    Update_Modal_Floating_Input_Label:'New Device ID*',
    Update_Modal_CTA_Text: 'Update',
    Cancel_CTA_Text: 'Cancel',
    Update_Id_Successful: 'Device ID update successful',
    EXCEL_CHECK_MSG: 'Please check and upload again',
    Upload_Failed_Msg:'Upload Failed. Please check file data and upload again.',
    Technical_Issue_Err: 'Due a technical issue, device ID cannot be update. Please try again after sometime',
    UploadedFile: "Uploaded File",
    Browse: "Browse",
    Update_Modal_SubTitle: "Upload CSV with updated Device ID",
    DownloadTemplate: "Download Template",
    Update_Modal_Title: "Change Device ID",
    Uploading: "Uploading",
    Uploaded: "Uploaded",
    Error: "Error in data",
    AcceptedFormats: "Accepted Formats:",
    CSV: "CSV",
    MaxFile: "Maximum file size:",
    MB: "50MB",
    UploadSuccess:  "Device ID update successful.",
    UploadError: "Data cannot be saved due to some technical error. Please upload the file again",
    FileDownloadSuccess: "File Download Successful.",
    FileDownloadError: "Download was interrupted. Please try again",
    NoData: "No Data Found",
    Delete_Moal_Title: "Confirm Device ID Deletion",
    Delete_Modal_SubTitle: "You are deleting mapped device ID, Are you sure you want to delete current device ID mapped to salesman ?",
    Confirm_CTA_Text: 'Confirm',
    Delete_DeviceId_Success: "The device ID has been deleted successfully",
    Delete_DeviceId_Error: "Device ID cannot not deleted due error. Please try again",
    Update: "Updating",
    FILE_SIZE: 'File size',
    DOWNLOAD_ALL: 'Download All'
}

export default SalesmanDeviceID;